import React, { useEffect , useState } from "react";
import Select from "react-select";

const FiltroEtiqueta = ({
  state,
  setState,
  etiquetas,
  filtradoCruzado,
  eventos,
  popUp,
  setPopUp,
  etiqueta =""
}) => {
  //console.log(" en  FiltroEtiqueta state - etiqueta" + JSON.stringify(etiqueta));
  
  const handleClickEtiquetaMenu = () => {
   // console.log(" FiltroEtiqueta handleClickEtiquetaMenu");
    setPopUp((s) => ({
      etiqueta: !s.etiqueta,
    }));
  };

  const HandleButtonEtiqueta = (value) => {
    //console.log(" FiltroEtiqueta HandleButtonEtiqueta");
    let newEtiquetasSeleccionadas;
    const existe = state.etiquetasSeleccionadas.find((x) => x === value);
    if (existe) {
      newEtiquetasSeleccionadas = state.etiquetasSeleccionadas.filter(
        (x) => x !== value
      );
    } else {
      newEtiquetasSeleccionadas = [...state.etiquetasSeleccionadas, value];
    }

    setState((s) => ({
      ...s,
      etiquetasSeleccionadas: newEtiquetasSeleccionadas,
    }));
  };

  // const handleButtonLimpiarFiltrosEtiqueta = () => {
  //   console.log(" FiltroEtiqueta handleButtonLimpiarFiltrosEtiqueta");
  //   let aux = {
  //     ...state,
  //     filtradoXEtiqueta: [],
  //     etiquetasSeleccionadas: [],
  //   };

  //   filtradoCruzado(aux);
  // };

  const handleClickAplicarEtiqueta = () => {
    console.log(" FiltroEtiqueta handleClickAplicarEtiquetaxxxxx: etiqueta : " + etiqueta);
    let newEventos = [];

    if (etiqueta ==="" && (state.etiquetasSeleccionadas || state.etiquetasSeleccionadas.length === 0 )  ) {
      console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 2 `);
      let aux = {
        ...state,
        filtradoXEtiqueta: eventos,
      };

      filtradoCruzado(aux);
    } else {
      
      console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 3 `);
      eventos.forEach((evento) => {
       // console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 4 `);
        evento.agenda_master_etiquetas.forEach((etiqueta) => {
          //console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 5 `);
          if (state.etiquetasSeleccionadas.includes(etiqueta.Nombre)) {
            //console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 6 `);
            const existe = newEventos.find((n) => n.id === evento.id);
            if (!existe) {
              //console.log(`FiltroEtiqueta handleClickAplicarEtiqueta 7 `);
              newEventos.push(evento);
            }
          }
        });
      });

      let aux = {
        ...state,
        eventos: [...newEventos],
        filtradoXEtiqueta: [...newEventos],
      };

      filtradoCruzado(aux);
    }
  };

  

  ///prueba cag
  //console.log("inicializa opciones");
  const tmpParamEtiqueta = etiquetas.filter(e=> (e.Nombre === etiqueta));
  console.log( `tmpParamEtiqueta ${ JSON.stringify(tmpParamEtiqueta)}`);
  const [selectedOptions, setSelectedOptions] = useState( tmpParamEtiqueta.map((x)=>({value:x.id , label:x.Nombre})) )//useState([{value:"Agenda-master-etiqueta_317",label:"Empleabilidad PUCP"}]);

  useEffect(() => {
    //if (etiqueta) 
    state.etiquetasSeleccionadas.push(etiqueta);
    handleClickAplicarEtiqueta();
  }, []);
  // Array of all options
  /*const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" }
  ];*/
  const optionList = etiquetas.map((x)=>({value:x.id , label:x.Nombre}));
   // Function triggered on selection
   function handleSelect(data) {
    
    //console.log(" FiltroEtiqueta handleSelect data : " + JSON.stringify(data));
    setSelectedOptions(data);

    if(data.length=== 0)  {

      //console.log(`FiltroEtiqueta data.length=== 0`)

      let aux = {
        ...state,
        filtradoXEtiqueta: eventos,
      };

      filtradoCruzado(aux);
    }
    else{
      //const newState = 
      //console.log(`FiltroEtiqueta else data.length> 0`)
      const newEventos = eventos.filter((evento)=>{

        const result = evento.agenda_master_etiquetas.some((etiqueta)=>{

          return data.some((x)=> {
            //console.log(`x.value ===etiqueta.id  x.label ${x.label} etiqueta.id ${etiqueta.Nombre } `)
            return (x.label ===etiqueta.Nombre);
          })
        });


        return result;
      });
      state.etiquetasSeleccionadas= data.map((x)=>(x.label));
      state.eventos = newEventos;

      //console.log("FiltroEtiqueta newEventos" + newEventos.length );
      //console.log("state.etiquetasSeleccionadas" + JSON.stringify(state.etiquetasSeleccionadas) );


      let aux = {
        ...state,
        eventos: [...newEventos],
        filtradoXEtiqueta: [...newEventos],
      };

      filtradoCruzado(aux);

    }
    //eventos.filter((x)=>(x.agenda_master_etiquetas))
    
  }
  //console.log("etiquetas : " + JSON.stringify(etiquetas));

  return (
    <div className="wrap-filter-fecha">
      <button
        data-w-id="6a608612-6999-814a-69ad-fabdfae7b8c1"
        className="btn-filter-home area w-button new-button"
        style={
          popUp
            ? {
                borderColor: "rgb(230, 231, 238)",
                backgroundColor: "rgb(230, 231, 238)",
              }
            : {}
        }
        onClick={handleClickEtiquetaMenu}
      >
        Etiqueta
      </button>
      <div
        className="pop-filter"
        style={popUp ? { opacity: 1, display: "block" } : {}}
      >
        <div className="w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <div className="wrap-pop-tag">
              <div className="app">
                <div className="dropdown-container" style={{minWidth: '200px'}} >
                  <Select
                    options={optionList}
                    placeholder="Seleccione etiqueta"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                    style={{minWidth:"200px"}}
                    noOptionsMessage={() => 'Sin coincidencias'}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltroEtiqueta;
