import React, { useState } from "react"
import moment from "moment"
import "moment/locale/es"
import GrillaEvento from "./grillaEvento"
import FiltroEtiqueta from "./filtroEtiqueta"

const EtiquetasScreen = ({ resultData, etiqueta }) => {
  moment.locale("es")
  console.log(`state-etiqueta : ${JSON.stringify(etiqueta)}` )

  const { etiquetas, next, variablesEntorno } = resultData

  const [state, setState] = useState({
    etiquetasSeleccionadas: [etiqueta && etiqueta],
    eventos: [...next],
    filtradoXEtiqueta: [],
    gruposMostrados: 1,
  })

  const [popUp, setPopUp] = useState({
    etiqueta: false,
  })

  const filtradoCruzado = aux => {
    let newEventos = []

    if (aux.filtradoXEtiqueta.length !== 0) {
      newEventos = aux.filtradoXEtiqueta
    } else {
      newEventos = [...newEventos]
    }
    setState({
      ...aux,
      eventos: [...newEventos],
    })
  }

  return (
    <>
      {/* <CarouselDestacado eventos={next} variablesEntorno={variablesEntorno} /> */}

      <div className="section-events">
        <div className="container-1200 w-container">
          <div className="cont-head-events">
            <div className="div-50 _w-f">
              <h2 className="h2-title-home">Resultados de búsqueda</h2>
            </div>
            <div className="div-50 _w-f">
              <div className="cont-btn-filtros">
                <FiltroEtiqueta
                  eventos={next}
                  filtradoCruzado={filtradoCruzado}
                  popUp={popUp.etiqueta}
                  setPopUp={setPopUp}
                  state={state}
                  setState={setState}
                  etiquetas={etiquetas}
                  etiqueta={etiqueta}
                />
              </div>
            </div>
          </div>
          <div className="line-white blue"></div>

          <GrillaEvento
            state={state}
            setState={setState}
            variablesEntorno={variablesEntorno}
          />
        </div>
      </div>
    </>
  )
}

export default EtiquetasScreen
